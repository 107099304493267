/**
 *
 * @param param0 the amount of days in the future you want the date to be.
 * @returns todays date plus however many days were provided as the prop to this function.
 */
export const getDateInFuture = ({ daysInFuture }: { daysInFuture: number }): Date => {
  const msInDay = 86400000;
  const futureDate = new Date().getTime() + msInDay * daysInFuture;

  return new Date(futureDate);
};
